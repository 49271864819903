.topprofilebox {
  flex: 1;
  margin: 1em;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.profilepic {
  min-width: 128px;
  min-height: 128px;
  border-radius: 50%;
  overflow: hidden;
  background: #aeaeae;
}
.infobox {
  font-size: 1em;
  margin-left: 1em;
  margin-right: 1em;
  overflow: hidden;
}
.name {
  color:black;
  font-size: 1.5em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.bio {
  color:black;
  font-size:1em;
}
.figures,.figure {
  color:black;
  font-size:1em;
}
.figures {
  display: flex;
  justify-content: space-around;
  margin-top: 1em;
  margin-bottom: 1em;
}
.figure {
  font-size:1em;
  margin:1em 0 0 1em;
}

.figure_value {
  font-size: 1.5em;
}

.figure_value, .figure_label {
  text-align: center;
  color:black
}
