/*
form {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0.5em;
  font-size: 1.25em;
}


input {
  padding: 0.5em;
  border: 0;
  border-bottom: #999999 solid 0.05em;
  background:none;
  font-size: 1em;
}

input::placeholder {
  color: rgba(85,85,85,0.5);
}

label {
  display: flex;
  color: #999999;
  white-space: nowrap;
  justify-content: flex-start;
  opacity: 0.5;
}

label > span:nth-child(2) {
  margin-left: 0.1em;
  margin-right: 0.1em;
}
*/

.fields {
  padding: 1em;
  font-size: 1.25em;
}

.field {
  flex: 1;
  margin: 0.5em;
}

.required {
  color: #4D4D4D;
}

.feedback {
  font-size: 0.8em;
  margin: 0.2em;
  height: 1em;
  justify-content: center;
}

.error {
  color: #4D4D4D;
}

.warning {

}

.buttons {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  display: flex;
  justify-content: center;
}


.radioGroupBox  {
  display: flex;
  justify-content: space-between;
  flex-direction: column;

}
.radioGroupLabel {
  color: #999999;
  margin:0 0 1em .5em;
}

.smallprint {
  font-size: 0.7em;
}
