.header {
  display: flex;
  align-items: center;
  flex-direction: row;
  background-color: #4D4D4D;
  padding: 0.5em;
  margin: 0;
}

.left {
  margin-right: 0.5em;
}

.main {
  flex: 1;
}

.main ol {
  justify-content: space-around;
}

.main li a {
  padding-left: 0.5em;
  padding-right: 0.5em;
}

.header a {
  color: #fff;
  text-decoration: none;
  background-color: #4D4D4D;
}
