.coming_soon {
  color: #999999;
  background-color: #dddddd;
  padding: 3em;
  margin: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.25em;
}
